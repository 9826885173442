<template>
  <div class="mobile-list">
    <div class="mobile-list-top flex-col" :style='bg'>
      <div class="flex-row fill-row">
        <Menu mode="horizontal" theme="primary" active-name="3" @on-select="menuclick">
          <MenuItem name="1" style="width: 33%;">
          <Icon />
          我的名片
          </MenuItem>
          <MenuItem name="2" style="width: 33%;">
          <Icon />
          我的任务
          </MenuItem>
          <MenuItem name="3" style="width: 33%;">
          <Icon />
          我的业绩
          </MenuItem>
        </Menu>
      </div>
      <div class="modile-list-top-row">
        <div class="modile-list-top-row-flex1" style="text-align: left;margin-left: 20px;">
          <Dropdown trigger="click">
            <a href="javascript:void(0)" style="color: white">
              2021年
              <Icon type="ios-arrow-down"></Icon>
            </a>
            <DropdownMenu slot="list">
              <DropdownItem>2019年</DropdownItem>
              <DropdownItem>2020年</DropdownItem>
              <DropdownItem>2021年</DropdownItem>
              <DropdownItem>2022年</DropdownItem>
              <DropdownItem>2023年</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <div class="modile-list-top-row flex-col">
        <label class="text-center margin-top-10 white-font-color text-32" style="margin: auto;">84.23</label>
        <label class="text-center margin-top-10 white-font-color text-10" style=" margin: auto;">总金额（万元）</label>
      </div>
      <div class="modile-list-top-row1 margin-top-10 ">
        <div class="modile-list-top-row1-flex2 "  style="border-right: 1px solid white;margin-bottom:10px" >
          <label class="text-center  white-font-color text-24" >84.23</label>
          <label class="text-center  white-font-color text-8" >已回款（万元）</label>
        </div>
        <div class="modile-list-top-row1-flex2 margin-bottom-10 ">
          <label class="text-center  white-font-color text-24" >84.23</label>
          <label class="text-center  white-font-color text-8" >未回款（万元）</label>
        </div>
      </div>
    </div>

    <div class="mobile-list-content">
      <orderScroll ref="scroll" :data="items" :options="options" :loading="loading" :firstPage="firstPage" :nextPage="nextPage">
        <taskbox :datas="items" :columns="columns" @button1_click="button1_click" @button2_click="button1_click" @button3_click="button3_click" />
      </orderScroll>
    </div>
  </div>
</template>

<script>
import bg_img from "@/static/images/bg_Rectangle.png"
import { formatDate, formatMoney } from "@/utils/format.js";
export default {

  data: function () {
    return {
      theme1: 'light',
      disabled: true,
      bg: {
        background: `url(${bg_img})`,
        // backgroundSize: '100% 100%',
        flex: '0 0 auto',
      },
      items: [

      ],
      options: {
        pullDownRefresh: {
          threshold: 60,
          stopTime: 500,
          txt: "刷新成功",
        },
        pullUpLoad: true,
      },
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      hasMore: true,
      showingFilter: false,
      filterOption: {
        startTime: null,
        endTime: null,
        orderStatusKey: 0,
      },
      enterkey: [],
      keyword: "",
      columns: [
        {
          type: "key_rightkey",
          key: "code",
          keyStyle: "color:black",
          rightkey: "stateName",
          rightkeyStyle: "color:red",
        },
        {
          type: "key",
          key: "source",
          keyStyle: "color:black",
        },
        {
          type: "key_rightkey",
          key: "username",
          keyStyle: "color:black",
          rightkey: "estimatedEndTime",
          rightkeyStyle: "color:black",
        },
        {
          type: "money_rightkey",
          moneykey: "money",
          moneyStyle: "color:red",
          rightkey: "company",
          rightkeyStyle: "color:#999",
        },
        {
          type: "button3",
          buttonname1: "下单",
          buttonname2: "开票",
          buttonname3: "回款",
        },

      ]
    };
  },
  methods: {
    formatDate: formatDate,
    formatMoney: formatMoney,
    menuclick(name) {
      let url = ""
      if (name == "1") {
        url = "mycard";
      } else if (name == "2") {
        url = "mytask";
      } else if (name == "3") {
        url = "myperformance";
      }
      this.$router.push({
        name: url,
      });
    },
    button1_click(item) {
      console.log(1);
      console.log(item);
    },
    button2_click(item) {
      console.log(2);
      console.log(item);
    },
    button3_click(item) {
      console.log(3);
      console.log(item);
    },
    cancel: function () {
      this.enterkey.length = 0;
    },
    add: function () {
    },
    itemClick: function (item) {
      console.log(item);
      // this.$router.push({
      //   name: "mCarCostDetail",
      //   query: {
      //     id: item.id,
      //   },
      // });
    },
    textChanged: function (keywords) {
      this.keyword = keywords;
    },
    filterClicked: function () {
      this.showingFilter = true;
      this.$nextTick(() => {
        this.$refs.searchDrawer.show();
      });
    },
    filterBgClicked: function () {
      this.showingFilter = false;
    },
    resetFilter: function () {
      this.filterOption = {
        startTime: null,
        endTime: null,
        orderStatusKey: 0,
        orderTypeKey: 1,
        orderSourceKey: null,
      };
      this.showingFilter = false;
      this.firstPage();
    },
    confirmFilter: function () {
      this.filterOption = this.$refs.filter.getOption();
      this.showingFilter = false;
      this.firstPage();
    },
    firstPage: function () {
      this.pageIndex = 1;
      this.dataRequest();
    },
    nextPage: function () {
      if (this.loading || !this.hasMore) {
        this.$refs.scroll.forceUpdate(true);
      } else {
        this.dataRequest();
      }
    },
    dataRequest: function () {
      this.items = [
        {
          code: "AAABBCCDD",
          stateName: "进行中",
          source: "协同平台",
          username: "徐维鑫",
          company: "坤达安",
          money: 22231.3,
        },
        {
          code: "ABCEDEFG",
          stateName: "进行中",
          source: "协同平台",
          username: "吕秋芬",
          company: "坤达安",
          money: 2223.3,
        },
        {
          code: "ACBCCCDC",
          stateName: "进行中",
          source: "协同平台",
          username: "袁周",
          company: "坤达安",
          money: 9382,
        },]
    }
  },
  mounted: function () {
    this.firstPage();
  },
};
</script>

<style lang="scss" scoped>
.mobile-input-disabled {
  .title {
    margin-left: 5px !important;
    padding-left: 6px !important;
    text-align: left;
  }
}

.mobile-list-task {
  .mobile-list-row {
    .mobile-list-row:last-child {
      width: 100%;
    }
  }
}
</style>
<style scoped>
.ivu-menu-item-active {
  color: white;
  border-bottom: 2px solid white;
}

.ivu-menu-primary {
  background: none;
}

.page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.top {
  flex: 0 0 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.top_top {
  flex: 0 0 30%;
}
.top_row {
  height: 35%;
  width: 100%;
  display: flex;
  flex-direction: row;
  border: none;
  text-align: center;
}
.top_row1 {
  height: 25%;
  width: 100%;
  display: flex;
  flex-direction: row;
  border: none;
  text-align: center;
}
.top_bottom_column3 {
  flex: 0 0 33%;
  display: flex;
  flex-direction: column;
}
.top_bottom_column2 {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
}
.lable {
  height: 20px;
  margin-top: 10px;
  color: white;
}

.top_bottom_column3 .a {
  color: white;
}
.img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
</style>